import { getAddress } from "@ethersproject/address";
import { AddressZero } from "@ethersproject/constants";
import { Token } from "@/constants/interface";
import { parse } from "qs";
import { store as reduxStore } from "@/state";
import BigNumber from "bignumber.js";

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value: any): string | false {
  try {
    // Alphabetical letters must be made lowercase for getAddress to work.
    // See documentation here: https://docs.ethers.io/v5/api/utils/address/
    return getAddress(value.toLowerCase());
  } catch {
    return false;
  }
}

// shorten the checksummed version of the input address to have 0x + 4 characters at start and end
export function shortenAddress(address: string, chars = 4): string {
  const parsed = isAddress(address);
  if (!parsed) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }
  return `${parsed.substring(0, chars + 2)}...${parsed.substring(42 - chars)}`;
}

export function showShortenAddress(address: string, chars = 4): string {
  const parsed = address;
  return `${parsed.substring(0, 5)}...${parsed.substring(42 - chars)}`;
}

export function showDomainAddress(address: string): string {
  const parsed = address;
  if(!parsed.length || !parsed) {
    return '--';
  }
  if(parsed.length === 1){
    return `${parsed}.scroll`;
  }
  if(parsed.length === 2) {
    return `${parsed.substring(0, 1)}....scroll`
  }
  return `${parsed.substring(0, 1)}...${parsed.substring(parsed.length - 1, parsed.length)}.scroll`;
}

export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

export const isZeroAddress = (address: string): boolean => {
  if (
    address === AddressZero ||
    address === "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"
  ) {
    return true;
  }
  return false;
};

export const isNativeTokenAddress = (address: string): boolean => {
  if (
    address === AddressZero ||
    address === "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee" ||
    // CELO native token
    address === "0x471ece3750da237f93b8e339c536989b8978a438"
  ) {
    return true;
  }
  return false;
};

export const isSameToken = (tokenA: Token, tokenB: Token): boolean =>
  tokenA.chain_id === tokenB.chain_id &&
  tokenA.address.toLowerCase() === tokenB.address.toLowerCase();

export const formatMoney = (amount: string) => {
  if (!amount) return;
  if (amount.includes(".")) {
    const [before, ...amountarr] = amount.split(".");
    console.log("before", before, amountarr, amount.split("."));
    const formatBefor = before.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return formatBefor + "." + amountarr.toString();
  }
  return amount.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const getPageQuery = () => {
  const urlParamArr = window.location.href.split("?");
  let paramObj: any = {};
  urlParamArr.forEach((item: any) => {
    paramObj = { ...paramObj, ...parse(item) };
  });
  Object.keys(paramObj).forEach(
    (item: any) => !paramObj[item] && delete paramObj[item]
  );
  return paramObj;
};

export function openNewTabURL(url: string) {
  const elem = window.document.createElement("a");
  elem.target = "_blank";
  elem.href = url;
  document.body.appendChild(elem);
  elem.click();
  document.body.removeChild(elem);
}

export const getDefaultDstToken = (dstChains: any, srcChainId: any) => {
  if (srcChainId?.toString() === "1" && dstChains[56]) {
    return dstChains[56];
  }
  return Object.values(dstChains)[0];
};

export const sortChains = (chains: any) => {
  const defaultSort = [1, 56, 42161, 137, 10, 43114, 250, 25, 100];
  let includeArr: any = [],
    excludeArr: any = [];
  chains.forEach((element: any) => {
    if (defaultSort.includes(element)) {
      includeArr.push(element);
    } else {
      excludeArr.push(element);
    }
  });
  includeArr.sort((a: any, b: any) => {
    return defaultSort.indexOf(a) - defaultSort.indexOf(b);
  });

  const resultArr = [...includeArr, ...excludeArr];
  return resultArr;
};

export const handleSignatureSuffix = (signature: string) => {
  if (typeof signature !== "string") return null;

  const editedSig = signature.slice(signature.length - 2, signature.length);
  let transformed = editedSig;
  switch (editedSig) {
    case "00":
      transformed = "1b";
      break;
    case "01":
      transformed = "1c";
      break;
    case "1b":
      transformed = "1b";
      break;
    case "1c":
      transformed = "1c";
      break;
    default:
      return null;
  }

  return transformed ? `0x${signature.slice(0, -2)}${transformed}` : null;
};

export const getUsdPrice = (value: string) => {
  const state = reduxStore.getState();
  const {
    user: { ethPrice },
  } = state;
  if (ethPrice && value) {
    const total = new BigNumber(value)
      .times(ethPrice)
      .dp(2, BigNumber.ROUND_UP)
      .toFixed();
    return `($ ${total})`;
  }
  return "";
};
