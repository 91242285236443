import { FC, PropsWithChildren } from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./state";
import Web3Provider from "./components/Web3Provider";
import { ToastContainer } from "react-toastify";
import { I18nProvider } from "@/components/I18nProvider";
import { QueryClientProvider } from "@tanstack/react-query";
import type { QueryClientProviderProps } from "@tanstack/react-query";
import { queryClient } from "./config/queryClient";
import { HashRouter } from "react-router-dom";
import UserInit from "@/state/user/init";
function Updaters() {
  return <></>;
}
function AppInit() {
  return (
    <>
      <UserInit />
    </>
  );
}

const QueryProvider = QueryClientProvider as FC<
  PropsWithChildren<QueryClientProviderProps>
>;

export const AppProvider: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <QueryProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <HashRouter>
            <I18nProvider>
              <Web3Provider>
                <AppInit />
                <Updaters />
                <ToastContainer
                  position="top-center"
                  autoClose={1500}
                  hideProgressBar
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                />
                {children}
              </Web3Provider>
            </I18nProvider>
          </HashRouter>
        </PersistGate>
      </Provider>
    </QueryProvider>
  );
};

// export const AppRouter: React.FC<PropsWithChildren> = ({ children }) => {
//   const Router = HashRouter;
//   const router = createHashRouter()
//   return (<RouterProvider router={router}/>)
//   // <Router>{children}</Router>;
// };
