import { request } from "../../utils/request";
import { getChain } from "@/connection/utils";
import {
  MY_DOMAIN_LIST,
  INVITE_RECORD_TOP,
  PROOF,
  INVITE_RECORD,
  PRICE_ETH,
} from "@/constants/requestAPI";
import { Contract } from "ethers";

export const CHAIN_ID = {
  TEST: 5,
  MAIN: 1,
};

export const getMyDomainList = (params: { account?: string }): Promise<any> => {
  return request(MY_DOMAIN_LIST, {
    params,
  });
};

export const getInviteRecordTop = (): Promise<any> => {
  return request(INVITE_RECORD_TOP);
};

export const getProof = (params: { account: string }) => {
  return request(PROOF, {
    params,
  });
};

export const getInviteRecord = (params: {
  wallet: string;
  sig: string;
}): Promise<any> => {
  return request(INVITE_RECORD, {
    params,
  });
};

export const createInviteRecord = (body: any): Promise<any> => {
  return request(INVITE_RECORD, {
    body,
  });
};

export const getEthPrice = (): Promise<any> => {
  return request(PRICE_ETH);
};

type NFTItem = {
  address: any;
  chainId: number;
};

type Address = {
  address: any;
  delivery_address: string;
  chain_id: number;
  token_id: string | number;
};

export const getNftList = (account: any, provider: any) => {
  return request("/api/nft_list", {
    params: { chain_id: getChain(), address: account },
  }).then((res) => {
    // @ts-ignore
    const list = res?.data;
    if (!list?.length) {
      return [];
    }

    // return Promise.all(list.map((item: any) => getNftInfo(item, signer)));
    return list;
  });
};

export const getNftStatus = () => {
  return request("/api/nft_status", {
    params: { chainId: getChain() },
  }).then((res) => {
    return {
      //@ts-ignore
      isStart: res?.data?.is_public_mint,
      //@ts-ignore
      isEnd: res?.data?.is_activity_end,
    };
  });
};

export const postReedeemAddress = (body: Address) => {
  return request("/api/nft", { body });
};
