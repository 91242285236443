import { createSlice } from "@reduxjs/toolkit";
export interface UserState {
  selectedWallet: string;
  language: string;
  theme: string;
  pricingMethod: string;
  personalSign: any;
  inviter: string;
  setDomainTip: boolean;
  ethPrice: number;
  historySearch: any;
  localRegistered: any;
  userProof: any;
  showLoginDialog: any;
}
export const initialState: UserState = {
  selectedWallet: "",
  language: "en",
  theme: "light",
  pricingMethod: "USD", //CNY
  personalSign: {},
  inviter: "",
  setDomainTip: false,
  ethPrice: 0,
  historySearch: [],
  localRegistered: {},
  userProof: {},
  showLoginDialog: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateSelectedWallet(state, { payload: { wallet } }) {
      state.selectedWallet = wallet;
    },
    updateLanguage(state, { payload: { language } }) {
      state.language = language;
    },
    updateTheme(state, { payload: { theme } }) {
      state.theme = theme;
    },
    updatePricingMethod(state, { payload: { pricingMethod } }) {
      state.pricingMethod = pricingMethod;
    },
    updatePersonalSign(state, { payload }) {
      const newPersonalSign = {
        ...state.personalSign,
        ...payload,
      };
      state.personalSign = newPersonalSign;
    },
    updateInviter(state, { payload: { inviter } }) {
      state.inviter = inviter;
    },
    updateSetDomainTip(state, { payload: { setDomainTip } }) {
      state.setDomainTip = setDomainTip;
    },
    updateEthPrice(state, { payload: { ethPrice } }) {
      state.ethPrice = ethPrice;
    },
    updateHistorySearch(state, { payload: { historySearch } }) {
      state.historySearch = historySearch;
    },
    updateLocalRegistered(state, { payload }) {
      const newlocalRegistered = {
        ...state.localRegistered,
        ...payload,
      };
      state.localRegistered = newlocalRegistered;
    },
    updateUserProof(state, { payload: { userProof } }) {
      state.userProof = userProof;
    },
    updateShowLoginDialog(state, { payload: { showLoginDialog } }) {
      state.showLoginDialog = showLoginDialog;
    },
  },
});

export const {
  updateSelectedWallet,
  updateLanguage,
  updateTheme,
  updatePricingMethod,
  updatePersonalSign,
  updateInviter,
  updateSetDomainTip,
  updateEthPrice,
  updateHistorySearch,
  updateLocalRegistered,
  updateUserProof,
  updateShowLoginDialog,
} = userSlice.actions;

export default userSlice.reducer;
