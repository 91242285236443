import {
  getOwnedTokenCount,
  getTotalSupply,
  isPublicMint,
  mint,
} from "@/helper/contract";
import { useWeb3React } from "@web3-react/core";
import React, { useEffect, useState } from "react";
import Proof from "@/whitelist.json";
import { infoToastTip } from "@/components/ui/ToastTip";
import { getNftStatus } from "../api";
import { useDispatch } from "react-redux";
import { updateShowLoginDialog } from "@/state/user/reducer";
import { ReactComponent as Loading } from "@/assets/svg/loading.svg";
import { useMedia } from "@/hooks/useMedia";

const MintMain = () => {
  const [num, setNum] = useState(1);
  const d = useDispatch();
  const [loading, setLoading] = useState(false);
  const [btnTxt, setBtnTxt] = useState("");
  const [disable, setDisable] = useState(false);
  const open = () => d(updateShowLoginDialog({ showLoginDialog: true }));
  const [maxMint, setMaxMint] = useState(5);
  const [supply, setSupply] = useState(0);
  const { isMobile } = useMedia();
  const { provider, account } = useWeb3React();
  const isDisable =
    loading || ["Whitelist Only", "Max Mint", "Comming Soon"].includes(btnTxt);
  useEffect(() => {
    let needUpdate = true;
    if (!account) {
      getNftStatus().then((r) => {
        if (!needUpdate) return;

        if (!r.isStart) {
          setBtnTxt("Comming Soon");
          setDisable(true);
        } else if (r.isEnd) {
          setBtnTxt("View Opensea");
        } else {
          setBtnTxt("Mint");
        }
      });
    } else {
      isPublicMint(provider, account).then((isStart) => {
        if (!isStart) {
          setBtnTxt("Comming Soon");
          setDisable(true);
        } else {
          getTotalSupply(provider, account).then((res) => {
            setSupply(res as unknown as number);
            if (res === 500) {
              setBtnTxt("View Opensea");
            } else {
              // @ts-ignore
              if (!Proof[account]) {
                setBtnTxt("Whitelist Only");
                setDisable(true);
              } else {
                getOwnedTokenCount(provider, account).then((c) => {
                  if (c >= 5) {
                    setBtnTxt("Max Mint");
                    setMaxMint(0);
                    setDisable(true);
                  } else {
                    setMaxMint(5 - c);
                    setBtnTxt("Mint");
                  }
                });
              }
            }
          });
        }
      });
    }

    return () => {
      if (!account) {
        needUpdate = false;
      }
    };
  }, [account, provider]);

  const doMint = async () => {
    if (!account) {
      open();

      return;
    }

    // @ts-ignore
    if (loading || !Proof[account]) return;

    if (btnTxt === "View Opensea") {
      window.open(
        "https://opensea.io/collection/juhnz-baekho-silver-needle"
      );

      return;
    }
    setLoading(true);
    try {
      // @ts-ignore
      await mint(provider, account, num, Proof[account].proof);

      setTimeout(() => {
        infoToastTip({
          status: "success",
          title: "mint successful",
          toastId: new Date().toString(),
        });

        getTotalSupply(provider, account).then((res) => {
          setSupply(res as unknown as number);
          if (res === 500) {
            setBtnTxt("View Opensea");
          } else {
            getOwnedTokenCount(provider, account).then((c) => {
              if (c >= 5) {
                setBtnTxt("Max Mint");
                setMaxMint(0);
                setDisable(true);
              } else {
                setMaxMint(5 - c);
                setBtnTxt("Mint");
              }
              setLoading(false);
            });
          }
        });
      }, 2000);
    } catch (e: any) {
      setLoading(false);

      infoToastTip({
        status: "fail",
        title: e.message?.includes("user rejected transaction")
          ? "user rejected transaction"
          : "Failed to Mint, please try again",
        toastId: new Date().toString(),
      });
    }
  };

  const trySet = (v: any) => {
    v = Number(v);
    if (v > maxMint) {
      setNum(maxMint);
    } else {
      setNum(v);
    }
  };

  const stauts =
    btnTxt === "Comming Soon"
      ? "Not Started"
      : btnTxt === "View Opensea"
      ? "Sold out"
      : btnTxt === "Max mint" || btnTxt === "Whitelist Only"
      ? "Live"
      : "Live";

  return (
    <div className={isMobile ? "mint-main mobile" : "mint-main"}>
      <div className="mint-main-left">
        <div className="wrapper">
          <div className="mint-left-title">Juhnz Baekho Silver Needle</div>
          <div className="mint-left-desc">
            Indulge in the exquisite world of Juhnz, where luxury meets the
            digital age. The Juhnz Baekho Silver Needle NFT is a gateway to a
            sensorial journey, meticulously crafted to elevate your tea
            experience and celebrate the rich heritage of Chinese culture. Each
            NFT can be redeemed for 1 box of Juhnz Baekho Silver Needle White
            Tea. The collection consists of 500 NFTs on the Ethereum blockchain.
          </div>
          <div className="mint-main-wrapper">
            <div className="mint-input-title">
              <div>Max Mint: {maxMint}</div>
              <div>Status: {stauts}</div>
            </div>
            <div className="mint-input">
              <img
                className={`${
                  isDisable || num <= 1 ? "mint-input-img-dis" : ""
                }`}
                src="https://juhnz.web3app.vip/images/minus.svg"
                width={44}
                height={44}
                onClick={() => {
                  if (isDisable || btnTxt === "View Opensea") return;

                  setNum((v) => (v > 1 ? v - 1 : 1));
                }}
              ></img>
              <input
                disabled={isDisable || btnTxt === "View Opensea"}
                type="number"
                value={num}
                onChange={(e) => trySet(e.target.value)}
              />
              <img
                onClick={() => {
                  if (isDisable || btnTxt === "View Opensea") return;
                  if (num < maxMint) {
                    setNum((v) => v + 1);
                  }
                }}
                width={44}
                height={44}
                className={`right ${
                  isDisable || num >= maxMint ? "mint-input-img-dis" : ""
                }`}
                src="https://juhnz.web3app.vip/images/plus.svg"
              ></img>
            </div>
            <div>
              <button
                style={{ border: "none" }}
                onClick={doMint}
                disabled={isDisable}
                className={isDisable ? "disabled" : ""}
              >
                {loading || !btnTxt ? (
                  <div className="mintLoading">
                    <Loading />
                  </div>
                ) : (
                  btnTxt
                )}
              </button>
            </div>
            <div>Mint Price: 0.3ETH Each</div>
          </div>
        </div>
      </div>
      <div className="mint-main-right">
        <img
          width={500}
          height={500}
          src="https://juhnz.web3app.vip/images/nft-main.png"
        ></img>
        <div className="supply">
          Total Supply: {btnTxt === "Comming Soon" ? 0 : supply}/500
        </div>
      </div>
    </div>
  );
};

const MintBottom = () => {
  const { isMobile, isBigPC } = useMedia();
  return (
    <>
      <div
        className={
          isMobile
            ? "mint-title mobile"
            : isBigPC
            ? "mint-title pc"
            : "mint-title"
        }
      >
        About The Tea
      </div>
      <div
        className={
          isMobile ? "mint-desc mobile" : isBigPC ? "mint-desc pc" : "mint-desc"
        }
      >
        Yin Zhen, otherwise known as "Silver Needle", is one of the most rare
        and sought-after teas in the world. Produced only in Fujian, China, this
        white tea was once a beverage only suitable for Emperors. Our particular
        Baekho Silver Needle is the pinnacle of white tea, produced with modern
        systems to ensure consistency, yet maintaining the essential traditional
        techniques that give it a deep and rich character. The final result
        reflects the exquisite craftsmanship, eye for detail, and high levels of
        quality throughout the entire production process. <br />
        As the years pass, white tea evolves into something extraordinary. Its
        flavour becomes more mellow and full, and its medicinal properties
        intensify. It's no surprise that the value of aged white tea only grows
        over time. Baekho Silver Needle's rarity makes it a prestigious
        collector's item with investment potential as it becomes more precious
        with each passing year.
      </div>
    </>
  );
};

const Mint = () => {
  return (
    <>
      <div className="mint-page">
        <MintMain />
        <MintBottom />
      </div>
    </>
  );
};

export default Mint;
