import { Connector } from "@web3-react/types";
import {
  ConnectionType,
  metamaskConnection,
  walletConnectConnection,
} from "./index";
import { CHAIN_ID } from "@/pages/api";

export function getIsInjected(): boolean {
  return Boolean(window.ethereum);
}

export function getIsMetaMask(): boolean {
  return window.ethereum?.isMetaMask ?? false;
}

export function getIsCoinbaseWallet(): boolean {
  return window.ethereum?.isCoinbaseWallet ?? false;
}

const CONNECTIONS = [metamaskConnection, walletConnectConnection];
export function getConnection(c: Connector | ConnectionType) {
  if (c instanceof Connector) {
    const connection = CONNECTIONS.find(
      (connection) => connection.connector === c
    );
    if (!connection) {
      throw Error("unsupported connector");
    }
    return connection;
  } else {
    switch (c) {
      case ConnectionType.METAMASK:
        return metamaskConnection;
      case ConnectionType.WalletConnect:
        return walletConnectConnection;
    }
  }
}

export function getConnectionName(connectionType: ConnectionType) {
  switch (connectionType) {
    case ConnectionType.METAMASK:
      return "MetaMask";
    case ConnectionType.WalletConnect:
      return "WalletConnect";
  }
}

export const getChain = () => CHAIN_ID.MAIN;