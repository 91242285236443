import { useWeb3React } from "@web3-react/core";
import { useCallback } from "react";
import { useAppDispatch } from "@/state/hooks";
import { switchChain } from "@/helper/utils/switchChain";

export default function useSelectChain() {
  const dispatch = useAppDispatch();
  const { connector } = useWeb3React();

  return useCallback(
    async (targetChain: any) => {
      if (!connector) return;

      try {
        await switchChain(connector, targetChain);
      } catch (error) {
        console.error("Failed to switch networks", error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [connector, dispatch]
  );
}
