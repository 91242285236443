import { ReactElement } from "react";
import useTranslation from "@/hooks/useTranslation";
import { TwitterIcon, DiscordIcon, MediumIcon } from "@/assets/svg-iconfont";
import { useMedia } from "@/hooks/useMedia";
interface IconArrInterface {
  icon: ReactElement;
  id: number;
  url: string;
}
const Footer = (): ReactElement => {
  const iconArr: Array<IconArrInterface> = [
    {
      icon: <TwitterIcon color="#fff" />,
      id: 1,
      url: "https://twitter.com/JuhnzOfficial",
    },
    // {
    //   icon: <DiscordIcon />,
    //   id: 2,
    //   url: "https://discord.gg/B3KgkyGbQx",
    // },
    // //     {
    // //     icon: <TelegramIcon />,
    // //     id: 3,
    // //     url: '',
    // // },
    // {
    //   icon: <MediumIcon />,
    //   id: 4,
    //   url: "https://mirror.xyz/0x5A606B7B89F744Ed8ed723AE0390a82307dFF916",
    // },
    //     {
    //     icon: <InIcon />,
    //     id: 5,
    //     url: '',
    // }
  ];

  const { isMobile, isBigPC } = useMedia();

  const openLinkUrl = (url: string): void => {
    window.open(url, "_blank");
  };

  return (
    <div
      className="page-footer"
      style={isMobile ? { height: "200px", marginTop: "40px" } : {}}
    >
      {isMobile || (
        <img
          width={600}
          height={400}
          src="https://juhnz.web3app.vip/images/bg3.png"
          style={{ position: "absolute", right: "20px", top: "0" }}
        />
      )}
      <div
        className={
          isMobile
            ? "page-footer-top mobile"
            : isBigPC
            ? "page-footer-top pc"
            : "page-footer-top"
        }
      >
        <div className="page-footer-icon-box">
          {iconArr.map((cur) => {
            return (
              <div
                className="page-footer-icon"
                style={isMobile ? {} : { marginTop: "60px" }}
                key={cur.id}
                onClick={() => openLinkUrl(cur.url)}
              >
                {cur.icon}
              </div>
            );
          })}
        </div>
      </div>
      <div
        className={
          isMobile
            ? "footer-desc mobile"
            : isBigPC
            ? "footer-desc pc"
            : "footer-desc"
        }
      >
        Juhnz explores a new realm of possibilities as it unveils Juhnz Baekho
        Silver Needle, the first NFT backed by luxury White Tea sourced from the
        mountains of Fujian, China.
      </div>
    </div>
  );
};
export default Footer;
