import { AppProvider } from "./AppProvider";
import {
  Routes,
  Route,
  useLocation,
  useNavigate,
  useMatch,
} from "react-router-dom";
import Header from "./components/ui/Header";
import Footer from "./components/ui/Footer";
import Intro from "./pages/intro";
import Home from "./pages/home";
import Reedeem from "./pages/reedeem";
import Introduce from "./pages/introduce";
import useTranslation from "@/hooks/useTranslation";
import classnames from "classnames";
import Mint from "./pages/mint";
import { useMedia } from "./hooks/useMedia";

export const App: React.FC<{}> = () => {
  return (
    <AppProvider>
      <AppDefault />
    </AppProvider>
  );
};

export const AppDefault = () => {
  let location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isMobile } = useMedia();
  const isHome = location?.pathname.includes("/nft");
  const isIntro = location?.pathname === "/";
  const isLandingpage = location?.pathname.includes("/introduce");

  return (
    <div
      className={classnames("root-box", {
        "root-box-other-pages": !isHome,
        intro: isIntro,
      })}
    >
      <div className="root-center">
        {isLandingpage || <Header isIntro={isIntro} />}
        <div className="page-common-content">
          <Routes>
            <Route path="/" element={<Intro />} />
            <Route path="/nft" element={<Home />} />
            <Route path="/mint" element={<Mint />} />
            <Route path="/redeem" element={<Reedeem />} />
            <Route path="/introduce" element={<Introduce />} />
          </Routes>
        </div>
        {isIntro || <Footer />}
      </div>
    </div>
  );
};
