import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import classnames from "classnames";
import Juhnz from "@/abis/goerli/Juhnz.json";
import { ReactComponent as Loading } from "@/assets/svg/loading.svg";

import { NameDetailsCancelIcon } from "@/assets/svg-iconfont";
import { yearsToSeconds } from "@/helper/utils";
import dayjs from "dayjs";
import { useWeb3React } from "@web3-react/core";
import { infoToastTip } from "@/components/ui/ToastTip";
import { blockExplorer } from "@/constants";
import { openNewTabURL } from "@/utils";
import copyToClipboard from "copy-to-clipboard";
import useTranslation from "@/hooks/useTranslation";
import ReedeemModal from "@/components/ui/ReedeemModal";
import { ReactComponent as Refresh } from "@/assets/svg/refresh.svg";
import { ReactComponent as Share } from "@/assets/svg/share.svg";
import { ReactComponent as NotFound } from "@/assets/svg/no-found.svg";
import { getNftList } from "../api";
import { redeem } from "@/helper/contract";
import { useMedia } from "@/hooks/useMedia";

const Reedeem = () => {
  const navigate = useNavigate();
  const { account, provider } = useWeb3React();
  const [list, setList] = useState([]);
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState<Record<string, any>>({});
  const [selectedFillAddressItem, setSelectedFillAddressItem] = useState("");
  const [loading, setLoading] = useState(false);
  const [listLoading, setListLoading] = useState(false);
  const { isMobile, isBigPC } = useMedia();

  const refresh = () => {
    setListLoading(true);
    getNftList(account, provider)
      .then((r) => {
        setList(r);
        setTimeout(() => {
          setListLoading(false);
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          setListLoading(false);
        }, 500);
      });
  };

  useEffect(() => {
    if (!account || !provider) {
      return;
    }

    refresh();
  }, [account, provider]);

  const [a, b]: [any[], any[]] = useMemo(() => {
    if (!list.length) {
      return [[], []];
    }
    return list.reduce(
      (s: [any[], any[]], i) => {
        // @ts-ignore
        if (i.state === "Redeemable") {
          s[0].push(i);
          // @ts-ignore
        } else if (i.state === "Redeemed") {
          s[1].push(i);
        }

        return s;
      },
      [[], []]
    );
  }, [list]);

  const disableRedeem =
    a.length === 0 || a.every((item) => !selected[item.token_id]);

  const doRedeem = async () => {
    try {
      if (loading) return;
      const tokenIds = Object.keys(selected).map((n) => Number(n));
      if (!provider || !account || !tokenIds.length) {
        return;
      }
      setLoading(true);
      const tx = await redeem(provider, account, tokenIds);
      await tx.wait();

      setTimeout(() => {
        infoToastTip({
          status: "success",
          title:
            "Redeem was successful, please refresh the page to update the status",
          toastId: `${new Date().valueOf()}`,
        });

        refresh();
        setLoading(false);
      }, 2000);
    } catch (error) {
      setLoading(false);
    }
  };

  const disableFillIn =
    b.length === 0 ||
    !selectedFillAddressItem ||
    b.every((item) => {
      return item.is_set_address;
    });

  const Item = ({ info, empty = false, onClick, selected = false }: any) => {
    if (empty) {
      return (
        <div className="mint-item not-found">
          <NotFound />
        </div>
      );
    }
    return (
      <div
        className={classnames("mint-item", {
          selected,
        })}
        onClick={() => onClick(info)}
      >
        <img
          style={{ objectFit: "cover", objectPosition: "center" }}
          src={info.image_url}
          width={320}
          height={320}
        />
        <div className="title-part">
          <span
            style={{ fontFamily: "sans-serif" }}
          >{`Juhnz #${info.token_id}`}</span>
          <div
            onClick={() => {
              window.open(
                `https://opensea.io/assets/ethereum/${Juhnz.address}/${info.token_id}`
              );
            }}
          >
            <Share />
          </div>
        </div>
        <div className="status-part">
          <span>Status</span>
          <span>
            {info.state === "Redeemable"
              ? "Redeemable"
              : info.is_set_address
              ? "Redeemed (address completed)"
              : "Redeemed (fill in your address)"}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div
      className={
        isMobile ? "page-my mobile" : isBigPC ? "page-my pc" : "page-my"
      }
    >
      <div className="page-my-title">
        <span>My Collection</span>
        <div
          onClick={refresh}
          style={{ cursor: "pointer" }}
          className={listLoading ? "rotate" : ""}
        >
          <Refresh />
        </div>
      </div>
      <div className="reedeemPart">
        <div className="reedeem-title">Redeemable NFTs</div>
        <div className="reedeem-desc">
          These NFTs can be redeemed for physical products. Clicking Redeem will
          change the statusof your NFT to Redeemed. After redeeming, please fill
          in your shipping address to receive your product below.
        </div>
        <div
          className={a.length === 1 ? "itemWrapper one" : "itemWrapper"}
          style={
            a.length > 0
              ? {}
              : { justifyContent: "center", gridTemplateColumns: "1fr" }
          }
        >
          {a.length ? (
            a.map((info, index) => (
              <Item
                key={index}
                info={info}
                selected={!!selected[info.token_id]}
                onClick={(info: any) => {
                  let m = { ...selected };
                  if (m[info.token_id]) {
                    m[info.token_id] = false;
                  } else {
                    m[info.token_id] = true;
                  }
                  setSelected(m);
                }}
              />
            ))
          ) : (
            <Item empty />
          )}
        </div>
        <div className="rd-btn">
          <button
            disabled={disableRedeem}
            className={disableRedeem ? "dis" : ""}
            onClick={doRedeem}
          >
            {loading ? (
              <div className="mintLoading">
                <Loading />
              </div>
            ) : (
              "Redeem"
            )}
          </button>
        </div>
      </div>
      <div className="reedeemPart">
        <div className="reedeem-title">Redeemed NFTs</div>
        <div className="reedeem-desc">
          These NFTs have already been redeemed for physical products. They can
          no longer be redeemed.
        </div>
        <div
          className={b.length === 1 ? "itemWrapper one" : "itemWrapper"}
          style={
            b.length > 0
              ? {}
              : { justifyContent: "center", gridTemplateColumns: "1fr" }
          }
        >
          {b.length ? (
            b.map((info: any, index: number) => (
              <Item
                key={index}
                info={info}
                selected={selectedFillAddressItem === info.token_id}
                onClick={(info: any) => {
                  if (info.is_set_address) {
                    return;
                  }

                  setSelectedFillAddressItem(
                    selectedFillAddressItem === info.token_id
                      ? ""
                      : info.token_id
                  );
                }}
              />
            ))
          ) : (
            <Item empty />
          )}
        </div>
        <div className="rd-btn">
          <button
            disabled={disableFillIn}
            className={disableFillIn ? "dis" : ""}
            onClick={() => setVisible(true)}
          >
            Fill in address
          </button>
        </div>
      </div>
      <ReedeemModal
        tokenId={selectedFillAddressItem}
        visible={visible}
        setVisible={setVisible}
        onComplete={() => {
          refresh();
          setSelectedFillAddressItem("");
        }}
      />
    </div>
  );
};

export default Reedeem;
