import { initSensors } from "./sensors";
import ReactDOM from "react-dom/client";
import { App } from "./App";
import { Buffer } from "buffer";
import { ClickToComponent } from "click-to-react-component";
import "@reach/dialog/styles.css";
import "react-tooltip/dist/react-tooltip.css";
import "./index.scss";

window.Buffer = Buffer;

initSensors();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <>
    <App />
    <ClickToComponent />
  </>
);
