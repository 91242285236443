import { Connector } from "@web3-react/types";
// import { store as reduxStore } from "@/state";

export const switchChain = async (connector: Connector, chainId: any) => {
  // const state = reduxStore.getState();
  // const {
  //   chain: { chainlist },
  // } = state;
  // const useChain = chainlist[chainId];
  // const rpcs = useChain ? useChain.rpcs : "";
  const addChainParameter = {
    chainId,
    chainName: chainId === 5 ? 'goerli' : 'Ethenum',
    // rpcUrls: rpcs,
    // nativeCurrency: "info.nativeCurrency",
    // blockExplorerUrls: "[info.explorer]",
  };
  await connector.activate(addChainParameter);
};
