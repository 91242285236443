import qs from "qs";

const initalConfig = {
  method: "GET",
  params: null,
  body: null,
  headers: {},
  cache: "no-cache",
  credentials: "same-origin",
};

const hostBaseURL = process.env.REACT_APP_HOSTBASE_URL;
if (typeof hostBaseURL === "undefined") {
  throw new Error(`hostBaseURL must be a defined environment variable`);
}

export const request = async (requestUrl: any, options?: any) => {
  let url = requestUrl;
  url = hostBaseURL + url;

  const tempConfig = { ...initalConfig, ...options };
  const { headers } = tempConfig;
  let { params, method, body } = tempConfig;

  if (params) {
    params = qs.stringify(params);
    url += `?${params}`;
  }

  if (body) {
    if (method === "delete") {
      method = "DElETE";
    } else {
      method = "POST";
    }
    headers["Content-Type"] = "application/json";
    body = JSON.stringify(body);
  }

  const config: any = {
    method: method.toUpperCase(),
    headers,
  };
  if (/^(POST|PUT|PATCH|DElETE)$/iu.test(method) && body) config.body = body;
  return new Promise((resolve, reject) => {
    fetch(url, config)
      .then((response) => {
        // const { status, statusText } = response;
        // if (status >= 200 && status < 400) {
        //   return response.json();
        // }
        return response.json();
        // console.log("request-error  00000", response.json());
        // const ErrorInfo = {
        //   code: "STATUS ERROR",
        //   status,
        //   statusText,
        // };
        // return Promise.reject(ErrorInfo);
      })
      .then((res) => {
        console.log(res, 'response1');
        console.log("request", { url, config, res, requestUrl });

        if (!res.code || !res.msg) {
          const ErrorInfo = {
            code: res.code,
            errorText: res.msg,
          };
          return Promise.reject(ErrorInfo);
        }
        resolve(res);
      })
      .catch((reason) => {
        console.log("request-error", { url, config, reason, requestUrl });
        reject(reason);
      })
      .finally(() => {
        // !finalDontCloseLoading && reduxStore?.dispatch(hideLoadingIndication());
      });
  });
};
