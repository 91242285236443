import React, { ReactElement, useState } from "react";
import { DialogOverlay, DialogContent } from "@reach/dialog";
import { ReactComponent as Loading } from "@/assets/svg/loading.svg";
import { useWeb3React } from "@web3-react/core";
import { mint, redeem } from "@/helper/contract";
import { txAwait } from "@/helper/utils/txAwait";
import classNames from "classnames";
import { infoToastTip } from "@/components/ui/ToastTip";
import { isAddress } from "@/utils";
import useTranslation from "@/hooks/useTranslation";
import { Field, Form, Formik, FormikProps } from "formik";
import PhoneInput from "react-phone-input-2";
import * as Yup from "yup";
import "react-phone-input-2/lib/style.css";
import { CHAIN_ID, postReedeemAddress } from "@/pages/api";
import { getChain } from "@/connection/utils";
import { useMedia } from "@/hooks/useMedia";

const ReedeemSchema = Yup.object().shape({
  fullname: Yup.string()
    .max(50, "fullname must between less than or equal to 50 digits!")
    .required("Required"),
  province: Yup.string()
    .max(50, "province must between less than or equal to 50 digits!")
    .required("Required"),
  country: Yup.string()
    .max(50, "country must between less than or equal to 50 digits!")
    .required("Required"),
  city: Yup.string()
    .max(50, "city must between less than or equal to 50 digits!")
    .required("Required"),
  shipingAddress: Yup.string()
    .max(200, "shipingAddress must between less than or equal to 200 digits!")
    .required("Required"),
  postalCode: Yup.string()
    .required("Required")
    .matches(/^[0-9]+$/, "Must be only digits")
    .max(20, "postalCode must between less than or equal to 20 digits!"),
  phone: Yup.string()
    .required("Required")
    .test("invalid format", (v) => {
      if (v.startsWith("+86")) {
        return /^(\+?0?86\-?)?1[345789]\d{9}$/.test(v);
      }
      if (v.startsWith("+1")) {
        return /^(\+?1)?[2-9]\d{2}[2-9](?!11)\d{6}$/.test(v);
      }

      if (v.startsWith("+852")) {
        return /^(\+?852\-?)?[569]\d{3}\-?\d{4}$/.test(v);
      }
      if (v.startsWith("+886")) {
        return /^(\+?886\-?|0)?9\d{8}$/.test(v);
      }

      return true;
    }),
  email: Yup.string().email("Invalid email").required("Required"),
});

const ReedeemModal = (props: any): ReactElement => {
  const { visible, type, name, onOk, setVisible, tokenId, onComplete } = props;

  const [status, setStatus] = useState("init");
  const [nameSetAddress, setNameSetAddress] = useState("");

  const { account, provider } = useWeb3React();
  const { t } = useTranslation();

  const onCancel = (reset: any) => {
    reset?.();
    setVisible(false);
  };

  const changeNameSetAddress = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const val = e.target.value ? e.target.value.trim() : "";
    setNameSetAddress(val);
  };

  const onSubmit = async (values: any, { setSubmitting, resetForm }: any) => {
    try {
      setSubmitting(true);
      console.log("value", values);
      const signer = provider?.getSigner();
      const delivery_address = JSON.stringify(values);
      const signature = await signer?.signMessage(delivery_address);

      const postData = {
        token_id: tokenId,
        address: account,
        delivery_address,
        chain_id: getChain(),
        signature,
      };

      const res: any = await postReedeemAddress(postData);

      if (res?.code !== 1001) {
        throw Error(res.msg);
      }

      infoToastTip({
        status: "success",
        title: "add address successful",
        toastId: `${new Date().valueOf()}`,
      });

      setVisible(false);
      onComplete?.();
      resetForm?.();
    } catch (error: any) {
      infoToastTip({
        status: "fail",
        title: error?.message || "fill address error",
        toastId: `${new Date().valueOf()}`,
      });
    }
  };

  const getError = (touched: any, errors: any, key: string) => {
    return touched[key] && errors[key] ? (
      <div style={{ color: "red" }} className="reedeem-vali-error">
        {errors[key]}
      </div>
    ) : null;
  };

  const { isMobile } = useMedia();

  return (
    <DialogOverlay isOpen={visible} dangerouslyBypassFocusLock>
      <DialogContent
        className={isMobile ? "reedeem-modal mobile" : "reedeem-modal"}
      >
        <Formik
          validateOnBlur
          validateOnChange
          enableReinitialize
          validationSchema={ReedeemSchema}
          initialValues={{
            fullname: "",
            country: "",
            postalCode: "",
            province: "",
            city: "",
            shipingAddress: "",
            phone: "",
            email: "",
          }}
          onSubmit={onSubmit}
        >
          {({
            isSubmitting,
            setFieldValue,
            errors,
            touched,
            handleSubmit,
            resetForm,
          }) => (
            <Form
              onSubmit={(e) => {
                handleSubmit(e);
              }}
            >
              <div
                className={
                  isMobile
                    ? "reedeem-modal-title mobile"
                    : "reedeem-modal-title"
                }
              >
                {status === "success"
                  ? "Successes"
                  : status === "fail"
                  ? "Redeem Failure"
                  : "Juhnz NFT Redeem Form"}
              </div>
              <div className="reedeem-modal-input">
                <div className="reedeem-label">Recipient's Full Name</div>
                <Field type="text" name="fullname" />
                {getError(touched, errors, "fullname")}
              </div>

              <div>
                <div className="reedeem-modal-input">
                  <div className="reedeem-label">Country</div>
                  <Field type="text" name="country" />
                  {getError(touched, errors, "country")}
                </div>
                <div className="reedeem-modal-input">
                  <div className="reedeem-label">Postal Code</div>
                  <Field name="postalCode" />
                  {getError(touched, errors, "postalCode")}
                </div>
              </div>

              <div className={isMobile ? "" : "input-grid-wrapper"}>
                <div className="reedeem-modal-input">
                  <div className="reedeem-label">Province/State</div>
                  <Field type="text" name="province" />
                  {getError(touched, errors, "province")}
                </div>
                <div className="reedeem-modal-input">
                  <div className="reedeem-label">City</div>
                  <Field type="text" name="city" />
                  {getError(touched, errors, "city")}
                </div>
                <div className="reedeem-modal-input">
                  <div className="reedeem-label">Detailed Shipping Address</div>
                  <Field type="text" name="shipingAddress" />
                  {getError(touched, errors, "shipingAddress")}
                </div>
              </div>

              <div className="reedeem-modal-input">
                <div className="reedeem-label">Contact Phone Number</div>
                <Field name="phone">
                  {({
                    field, // { name, value, onChange, onBlur }
                    form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  }: any) => (
                    <PhoneInput
                      enableSearch
                      disableSearchIcon
                      showDropdown
                      country={"cn"}
                      value={field.value}
                      onChange={(e) => {
                        form.setFieldTouched("phone", true);
                        setFieldValue("phone", "+" + e);
                      }}
                    />
                  )}
                </Field>
                {getError(touched, errors, "phone")}
              </div>

              <div className="reedeem-modal-input">
                <div className="reedeem-label">Email</div>
                <Field name="email" />
                {getError(touched, errors, "email")}
              </div>

              <div className="reedeem-button-area">
                <div
                  className="btn-div reedeem-modal-cancel-btn"
                  onClick={() => {
                    onCancel(resetForm);
                  }}
                >
                  Cancel
                </div>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className={classNames("btn-div reedeem-modal-confirm-btn", {
                    "btn-loading": isSubmitting,
                  })}
                >
                  {isSubmitting ? <div className="btn-loading-icon" /> : null}
                  {isSubmitting ? (
                    <div className="mintLoading">
                      <Loading />
                    </div>
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </DialogOverlay>
  );
};

export default ReedeemModal;
