import { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { useAppDispatch } from "@/state/hooks";
import {
  updatePersonalSign,
  updateEthPrice,
  updateHistorySearch,
  updateUserProof,
} from "./reducer";
import { store as reduxStore } from "@/state";
import { useQuery } from "@tanstack/react-query";
import { getEthPrice } from "@/pages/api";
import useSelectChain from "@/hooks/useSelectChain";
import { getProof } from "@/pages/api";
import { getChain } from "@/connection/utils";

export default function UserInit() {
  const dispatch = useAppDispatch();
  const [loginAccount, setLoginAccount] = useState("");
  const { account, provider, chainId } = useWeb3React();
  const selectChain = useSelectChain();

  useEffect(() => {
    if (account) {
      setLoginAccount(account);
    } else {
      setLoginAccount("");
    }
  }, [account]);

  //selectChain
  useEffect(() => {
    if (loginAccount && chainId && chainId !== getChain()) {
      selectChain(getChain());
    }
  }, [loginAccount, chainId]);

  return null;
}

export const setHistorySearch = (value: string) => {
  const state = reduxStore.getState();
  const {
    user: { historySearch },
  } = state;
  let result: any = [];
  if (historySearch.includes(value)) {
    result = historySearch.filter((item: string) => item !== value);
    result.push(value);
  } else {
    result = [...historySearch];
    result.push(value);
    if (result.length > 5) {
      result.shift();
    }
  }
  reduxStore.dispatch(updateHistorySearch({ historySearch: result }));
};
