import React, { useCallback, useRef, useState } from "react";
import { ReactComponent as StartPlay } from "@/assets/svg/video-circle.svg";
import { useNavigate } from "react-router-dom";
import { ReactComponent as JuhnzIcon } from "@/assets/svg/juhnz.svg";
import { useMedia } from "@/hooks/useMedia";

export default function Introduce(props: any) {
  const navigate = useNavigate();
  const { isMobile } = useMedia();
  const [v, setV] = useState(false);
  const goToHome = () => {
    navigate("/");
  };
  const r = useRef<any>();

  return (
    <div className="introduce">
      <JuhnzIcon />
      <div className={isMobile ? "wrapper wrapper-mobile" : "wrapper"}>
        <div className="left" style={{ height: isMobile ? "auto" : "360px" }}>
          <div className={isMobile ? "hh hh-mobile" : "hh"}>
            <div className="title">
              <div>Juhnz</div>
              <div>Baekho Silver Needle</div>
            </div>
            <div className="detail">
              <div>
                <span>First Minting Date:</span>
                <span>2023/09/15</span>
              </div>

              <div>
                <span>Mint Price:</span>
                <span>0.3 ETH</span>
              </div>
              <div>
                <span>Total Supply</span>
                <span>500</span>
              </div>
            </div>
          </div>
          <div className="box" />
        </div>
        <div className={isMobile ? "right right-mobile" : "right"}>
          <video
            style={{ objectFit: "cover" }}
            ref={(v: HTMLVideoElement) => {
              if (v) {
                r.current = v;
                r.current.onended = () => {
                  r.current.autoplay = false;
                  r.current.load?.();
                  setV(false);
                };
              } else {
                r.current = null;
              }
            }}
            width={340}
            height={isMobile ? 160 : 440}
          >
            <source
              src="https://juhnz.web3app.vip/video/tea_en.mp4"
              type="video/mp4"
            />
          </video>
          {v || (
            <StartPlay
              onClick={(e) => {
                r.current.play?.();
                setV(true);
              }}
              className={isMobile ? "pp mobile" : "pp"}
            />
          )}
          <div className="text">How to Brew the Perfect Cup</div>
        </div>
      </div>
      <button style={{ position: "static" }} onClick={goToHome}>
        Go To Homepage
      </button>
    </div>
  );
}
