import { ConnectionType } from "../connection";
import { getConnection } from "../connection/utils";
import { useMemo } from "react";
import { useAppSelector } from "../state/hooks";

const SELECTABLE_WALLETS = [
  ConnectionType.METAMASK,
  ConnectionType.WalletConnect,
];

export default function useOrderedConnections() {
  const selectedWallet = useAppSelector(
    (state: any) => state.user.selectedWallet
  );
  return useMemo(() => {
    const orderedConnectionTypes: ConnectionType[] = [];
    if (selectedWallet) {
      orderedConnectionTypes.push(selectedWallet);
    }
    orderedConnectionTypes.push(
      ...SELECTABLE_WALLETS.filter((wallet) => wallet !== selectedWallet)
    );

    return orderedConnectionTypes.map(getConnection).filter(Boolean);
  }, [selectedWallet]);
}
