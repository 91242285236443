import { useCallback } from "react";
import { useTranslation as useTranslationI18next } from "react-i18next";

const useTranslation = () => {
  const tResult = useTranslationI18next();

  const t: any = useCallback(
    (value: any, params?: any) => {
      return tResult.t(`${value}.message`, params ? params : {});
    },
    [tResult]
  );

  return { t };
};

export default useTranslation;
