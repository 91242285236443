import { initializeConnector, Web3ReactHooks } from "@web3-react/core";
import { MetaMask } from "@web3-react/metamask";
import { WalletConnect } from "@web3-react/walletconnect-v2";
import { Connector } from "@web3-react/types";

type ChainConfig = {
  [chainId: number]: any;
};

export const CHAINS: ChainConfig = {
  1: {
    urls: ["https://cloudflare-eth.com"].filter(Boolean),
    name: "Mainnet",
  },

  5: {
    urls: [
      "https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
    ].filter(Boolean),
    name: "Goerli",
  },
};

export const URLS: { [chainId: number]: string[] } = Object.keys(
  CHAINS
).reduce<{ [chainId: number]: string[] }>((accumulator, chainId) => {
  const validURLs: string[] = CHAINS[Number(chainId)].urls;

  if (validURLs.length) {
    accumulator[Number(chainId)] = validURLs;
  }

  return accumulator;
}, {});

export enum ConnectionType {
  METAMASK = "METAMASK",
  WalletConnect = "WalletConnect",
}

export interface Connection {
  connector: Connector;
  hooks: Web3ReactHooks;
  type: ConnectionType;
}

function onError(error: Error) {
  console.debug(`web3-react error: ${error}`);
}

const [web3Metamask, web3MetamaskHooks] = initializeConnector<MetaMask>(
  (actions) => {
    console.log("initializeConnector web3Metamask", actions);
    return new MetaMask({ actions, onError });
  }
);

const [walletConnect, walletConnectHooks] = initializeConnector<WalletConnect>(
  (actions) =>
    new WalletConnect({
      actions,
      options: {
        chains: [1],
        optionalChains: [5],
        rpc: URLS,
        showQrModal: true,
        projectId: "2cc39ec0fa75515e7b974082b8be6fbe",
      },
    })
);

export const metamaskConnection: Connection = {
  connector: web3Metamask,
  hooks: web3MetamaskHooks,
  type: ConnectionType.METAMASK,
};

export const walletConnectConnection: Connection = {
  connector: walletConnect,
  hooks: walletConnectHooks,
  type: ConnectionType.WalletConnect,
};
