export const homePageViewEvent = () => {
  window.sensors?.track("$pageview", {
    $url: 'Scroll_Index_name',
  });
};

export const webClickEvent = (element_id: string, element_content?: string) => {
  window.sensors?.track("$WebClick", {
    $element_id: element_id,
    $element_content: element_content || "",
  });
};
