import {
  useState,
  useCallback,
  useMemo,
  ReactElement,
  MouseEvent,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import { useWeb3React } from "@web3-react/core";
import type { Connector } from "@web3-react/types";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "@/state/hooks";
import { DialogOverlay, DialogContent } from "@reach/dialog";
import { showShortenAddress } from "@/utils";
import { getChain, getConnection } from "@/connection/utils";
import { metamaskConnection, walletConnectConnection } from "@/connection";
import {
  updateSelectedWallet,
  updateLanguage,
  updateShowLoginDialog,
} from "@/state/user/reducer";
import copyToClipboard from "copy-to-clipboard";
import { infoToastTip } from "@/components/ui/ToastTip";
import { ReactComponent as CloseIcon } from "@/assets/svg/close.svg";
import { ReactComponent as MetamaskLogo } from "@/assets/svg/metamask-logo.svg";
import { ReactComponent as WalletConnectLogo } from "@/assets/svg/walletconnect-logo.svg";
import { ReactComponent as ArrowDown } from "@/assets/svg/arrow-down.svg";
import { ReactComponent as LogOutIcon } from "@/assets/svg/logout.svg";
import { ReactComponent as ArrowRight } from "@/assets/svg/arrow-right.svg";
import { ReactComponent as CopyIcon } from "@/assets/svg/copy.svg";
import { ReactComponent as JuhnzIcon } from "@/assets/svg/juhnz.svg";
import { ReactComponent as Menu } from "@/assets/svg/burger.svg";
import { webClickEvent } from "@/helper/utils/sensors";
import useTranslation from "@/hooks/useTranslation";
import { useDispatch } from "react-redux";
import { CHAIN_ID } from "@/pages/api";
import { useMedia } from "@/hooks/useMedia";

interface WalletsInterface {
  name: string;
  logo: ReactElement;
  connector: Connector;
  wallet: string;
  des?: string;
}

const UserButton = forwardRef((props: any, ref) => {
  const { account } = useWeb3React();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const open = () => dispatch(updateShowLoginDialog({ showLoginDialog: true }));

  return (
    <>
      {account ? (
        <div
          className="user-button"
          onClick={() => props.setShowLogOutDialog(true)}
        >
          {showShortenAddress(account)}
          <ArrowDown />
        </div>
      ) : (
        <div className="user-button" onClick={open}>
          {t("YkqSDwKovDFmDjUTnt")}
        </div>
      )}
    </>
  );
});

const Header: any = (props: any) => {
  let location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { account, connector } = useWeb3React();
  const dispatch = useDispatch();
  const { isMobile } = useMedia();
  const [showMobileDialog, setShowMobileDialog] = useState(false);
  const [showLogOutDialog, setShowLogOutDialog] = useState(false);
  const [loadingWallet, setLoadingWallet] = useState("");
  const navi = useNavigate();
  const isIntro = props.isIntro;
  const isHome = location?.pathname === "/nft" || location?.pathname === "/";

  const wallets: Array<WalletsInterface> = [
    {
      name: "MetaMask",
      logo: <MetamaskLogo />,
      connector: metamaskConnection.connector,
      wallet: "METAMASK",
    },
    {
      name: "WalletConnect",
      logo: <WalletConnectLogo />,
      connector: walletConnectConnection.connector,
      wallet: "WalletConnect",
    },
  ];

  const { selectWallet } = useMemo(() => {
    const type = getConnection(connector)?.type || "";
    const selectWallet = type
      ? wallets.filter((item: any) => item.wallet === type)
      : [];

    return {
      selectWallet: selectWallet[0],
    };
  }, [connector]);

  const showLoginDialog = useAppSelector((state) => state.user.showLoginDialog);

  const open = () => dispatch(updateShowLoginDialog({ showLoginDialog: true }));

  const close = () => {
    dispatch(updateShowLoginDialog({ showLoginDialog: false }));
    setLoadingWallet("");
  };

  useEffect(() => {
    if (!isMobile) {
      setShowMobileDialog(false);
    }
  }, [isMobile]);

  const oepnMobileModal = () => {
    if (!isMobile) {
      return;
    }
    setShowMobileDialog(true);
  };

  const goHomeFunc = (): void => {
    navigate(`/nft`);
  };

  const r = useRef();

  const tryActivation = useCallback(
    async (connector: Connector, wallet: string) => {
      try {
        setLoadingWallet(wallet);
        const c = getChain();
        if (wallet === "WalletConnect") {
          // @ts-ignore
          connector.chains = [c];
          // @ts-ignore
          connector.optionalChains = [c];
          // @ts-ignore
          connector.options.chains = [c];
          // @ts-ignore
          connector.options.optionalChains = [c];
        }
        await connector.activate(c);
        dispatch(updateShowLoginDialog({ showLoginDialog: false }));
        dispatch(updateSelectedWallet({ wallet }));
        // webClickEvent("Scroll_Ag_walletconnect");
        setLoadingWallet("");
      } catch (error) {
        setLoadingWallet("");
        console.log(`web3-react connection error: ${error}`);
      }
    },
    [dispatch]
  );

  const disconnectWallet = () => {
    if (connector.deactivate) {
      walletConnectConnection.connector.deactivate?.();
      connector.deactivate?.();
    } else {
      connector.resetState();
    }
    setShowLogOutDialog(false);
    dispatch(updateSelectedWallet({ wallet: "" }));

    if (
      window.location.hash.includes("/redeem") ||
      window.location.hash.includes("/mint")
    ) {
      navi("/");
    }
  };

  const clickCopyFun = (copyValue: string) => {
    copyToClipboard(copyValue);
    infoToastTip({
      status: "success",
      title: t("nBMZbjmYxYoLgLGLz"),
      toastId: `setUpdate${new Date().valueOf()}`,
    });
  };

  const isInjected = Boolean(window.ethereum);

  return (
    <>
      <div
        className={isMobile ? "page-header page-header-mobile" : "page-header"}
        style={
          isIntro
            ? { background: "transparent", position: "fixed", top: 0, left: 0 }
            : {}
        }
      >
        <div
          className={
            isIntro ? "page-header-logo intro-svg" : "page-header-logo"
          }
        >
          <div className={"page-header-logo-icon"} onClick={goHomeFunc}>
            <JuhnzIcon />
          </div>
        </div>
        {isMobile ? (
          <Menu
            className={isHome ? "menu-home" : ''}
            onClick={oepnMobileModal}
          />
        ) : (
          <div className="page-header-right">
            <div
              className={"my-domain-btn" + (isHome ? " is-home" : "")}
              onClick={() => {
                setShowMobileDialog(false);

                account ? navigate("/mint") : open();
              }}
            >
              Mint
              {/* in ChainId {chainId} */}
            </div>
            <div
              className={"my-domain-btn" + (isHome ? " is-home" : "")}
              onClick={() => {
                setShowMobileDialog(false);
                account ? navigate("/redeem") : open();
              }}
            >
              My Juhnz
            </div>
            <UserButton setShowLogOutDialog={setShowLogOutDialog} />
          </div>
        )}
      </div>
      <DialogOverlay
        isOpen={showLoginDialog}
        onDismiss={close}
        dangerouslyBypassFocusLock
        dangerouslyBypassScrollLock
      >
        <DialogContent
          className={isMobile ? "wallet-modal mobile" : "wallet-modal"}
        >
          <div>
            <div className="header">
              <div className="header-title">{t("YkqSDwKovDFmDjUTnt")}</div>
              <div className="close-icon" onClick={close}>
                <CloseIcon />
              </div>
            </div>
            <div className="content">
              {wallets.map((item: any) => (
                <div
                  className="wallet-item"
                  key={item.name}
                  onClick={() => {
                    tryActivation(item.connector, item.wallet);
                  }}
                >
                  <div className="wallet-item_title">
                    <div className="wallet-item_title_left">
                      <div className="wallet-item_logo">{item.logo}</div>
                      <span className="wallet-item_name">{item.name}</span>
                    </div>
                    {item.name === "MetaMask" && !isInjected ? (
                      <span className="wallet-item_uninjected">
                        {t("ZkOPSEmFIvwVIwZHnQu")}
                      </span>
                    ) : loadingWallet === item.wallet ? (
                      <div className="wallet-item_title_loading" />
                    ) : (
                      <ArrowRight />
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </DialogContent>
      </DialogOverlay>
      <DialogOverlay
        style={{ background: "#e1e0dfunset" }}
        isOpen={showLogOutDialog}
        onDismiss={() => setShowLogOutDialog(false)}
      >
        <DialogContent
          className={
            isMobile ? "wallet-logout-modal mobile" : "wallet-logout-modal"
          }
        >
          <div className="wallet-logout-modal_wallet">
            <div className="wallet-logout-modal_wallet_info">
              {selectWallet?.logo} <span>{selectWallet?.name}</span>
            </div>
            <div
              className="wallet-logout-modal_wallet_logout"
              onClick={disconnectWallet}
            >
              <LogOutIcon />
              Disconnect
            </div>
          </div>
          <div className="wallet-logout-modal_wallet-address">
            <div className="wallet-logout-modal_wallet-address-val">
              {account ? showShortenAddress(account) : ""}
            </div>
            {account ? (
              <div
                className="wallet-logout-modal_wallet-address-copy"
                onClick={() => {
                  clickCopyFun(account);
                }}
              >
                <CopyIcon />
              </div>
            ) : null}
          </div>
        </DialogContent>
      </DialogOverlay>
      <DialogOverlay
        isOpen={showMobileDialog}
        onDismiss={() => {
          setShowMobileDialog(false);
          // @ts-ignore
          r.current?.close?.();
        }}
        dangerouslyBypassScrollLock
      >
        <DialogContent
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            bottom: 0,
            top: 0,
            margin: 0,
            width: "100vw",
            height: "100vh",
            boxSizing: "border-box",
            background: "#e1e0df",
          }}
        >
          <div
            style={{
              position: "absolute",
              right: "20px",
              top: "20px",
              zIndex: "100",
              boxSizing: "border-box",
            }}
            onClick={() => {
              setShowMobileDialog(false);
              // @ts-ignore
              r.current?.close?.();
            }}
          >
            <CloseIcon />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "30px",
              alignItems: "center",
              marginTop: "50px",
            }}
          >
            <UserButton setShowLogOutDialog={setShowLogOutDialog} ref={r} />
            <div
              className="my-domain-btn mobile"
              onClick={() => {
                setShowMobileDialog(false);
                account ? navigate("/mint") : open();
              }}
            >
              Mint
              {/* in ChainId {chainId} */}
            </div>
            <div
              className="my-domain-btn mobile"
              onClick={() => {
                setShowMobileDialog(false);

                account ? navigate("/redeem") : open();
              }}
            >
              My Juhnz
            </div>
          </div>
        </DialogContent>
      </DialogOverlay>
    </>
  );
};

export default Header;
