import React, { useRef, useState } from "react";
import { ReactComponent as StartPlay } from "@/assets/svg/video-circle.svg";
import { useNavigate } from "react-router-dom";
import { useMedia } from "@/hooks/useMedia";

export default function Intro(props: any) {
  const navigate = useNavigate();
  const [v, setV] = useState(false);
  const goToNFT = () => {
    navigate("/nft");
  };
  const videoRef = useRef<any>();
  const { isMobile } = useMedia();

  const startPlay = () => {
    setV(true);
    videoRef.current?.play?.();
  };

  return (
    <div className="intro">
      <video
        ref={(d) => {
          if (d) {
            videoRef.current = d;
            videoRef.current.onended = () => {
              videoRef.current.autoplay = false;
              videoRef.current.load?.();
              setV(false);
            };
          } else {
            videoRef.current = null;
          }
        }}
        poster="https://juhnz.web3app.vip/images/intro.jpeg"
        style={{
          objectFit: "cover",
          width: "100vw",
          height: "100vh",
        }}
      >
        <source
          src="https://juhnz.web3app.vip/video/home.mov"
          type="video/mp4"
        />
        <source
          src="https://juhnz.web3app.vip/video/home.mov"
          type="video/ogg"
        />
      </video>
      {v || (
        <button className="playIncon" style={{
           border: 'none',
           background: '#000',
           color: '#e1e0e0',
           padding: '12px 45px',
           borderRadius: '50px',
           fontSize: '20px'
        }} onClick={startPlay}>
          PLAY
        </button>
      )}
      <div
        className={isMobile ? "startBtnWrapper mobile" : "startBtnWrapper"}
        onClick={goToNFT}
      >
        <button className="startBtn">start now</button>
        <img src="https://juhnz.web3app.vip/images/start.svg" />
      </div>
    </div>
  );
}
