import { isAddress } from "@/utils";
import { AddressZero } from "@ethersproject/constants";
import { Contract } from "@ethersproject/contracts";
import type { JsonRpcProvider, JsonRpcSigner } from "@ethersproject/providers";
import { Interface } from "@ethersproject/abi";
import { getRpcProvider } from "../utils/rpcProvider";
import { BigNumber, ethers, utils as ethersUtils } from "ethers";
import { yearsToSeconds } from "../utils";
import BigNumberJs from "bignumber.js";

import JuhnzContract from "@/abis/goerli/Juhnz.json";
import ERC721_ABI from "@/abis/erc721.json";
import { parseEther } from "ethers/lib/utils";
import { getChain } from "@/connection/utils";

// account is not optional
function getSigner(provider: JsonRpcProvider, account: string): JsonRpcSigner {
  return provider.getSigner(account).connectUnchecked();
}

// account is optional
function getProviderOrSigner(
  provider: JsonRpcProvider,
  account?: string
): JsonRpcProvider | JsonRpcSigner {
  return account ? getSigner(provider, account) : provider;
}

// account is optional
export function getContract(
  address: string,
  ABI: any,
  provider: JsonRpcProvider,
  account?: string
): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }

  return new Contract(
    address,
    ABI,
    getProviderOrSigner(provider, account) as any
  );
}

type NameStatus = "registered" | "available" | "notsale" | "release";

//域名注册
export const registerName = async (
  provider: JsonRpcProvider,
  account: string,
  contractAddress: string,
  gasLimit: string,
  callData: any,
  value: any
) => {
  try {
    const signer = await provider?.getSigner();
    let txnReq: any = {
      to: contractAddress,
      from: account,
      data: callData,
      value: value,
    };
    const getGasLimit: any = (await signer.estimateGas(txnReq))
      .mul(105)
      .div(100);
    const newGasLimit = getGasLimit.toString();
    if (!newGasLimit) {
      throw new Error("GasLimit error");
    }
    const tx = { ...txnReq, gasLimit: newGasLimit };
    const result = await signer.sendTransaction(tx);
    return result;
  } catch (err: any) {
    console.error("sendTransaction error", err, {
      to: contractAddress,
      from: account,
      data: callData,
      value: value,
    });
    throw new Error("sendTransaction error");
  }
};

// 域名续期
export const nameRenew = async (
  provider: JsonRpcProvider,
  account: string,
  contractAddress: string,
  gasPrice: string,
  gasLimit: string,
  callData: any,
  value: any
) => {
  try {
    const signer = provider?.getSigner();
    let txnReq: any = {
      to: contractAddress,
      from: account,
      data: callData,
      value: value ? new BigNumberJs(value).shiftedBy(18).toFixed() : "0",
    };
    const getGasLimit: any = (await signer.estimateGas(txnReq))
      .mul(105)
      .div(100);
    const newGasLimit = getGasLimit.toString();
    if (!newGasLimit) {
      throw new Error("GasLimit error");
    }
    const tx = { ...txnReq, gasLimit: newGasLimit };
    console.log("sendTransaction tx", tx);
    const res = await signer.sendTransaction(tx);
    console.log("sendTransaction resresres", res);
    return res;
  } catch (err: any) {
    console.log("sendTransaction error", err);
    throw new Error("sendTransaction error");
  }
};

// gasprice
export const getGasPrice = async () => {
  const provider = await getRpcProvider(getChain());
  try {
    const resultPromise: any = await provider.getGasPrice();
    const resultPromiseString = resultPromise.mul(105).div(100).toString();
    return resultPromiseString;
  } catch (err) {
    return "";
  }
};

export const isPublicMint = async (
  provider: JsonRpcProvider | undefined,
  account: string
) => {
  const signer = provider?.getSigner();
  if (!signer || !account) return;
  const c = new Contract(JuhnzContract.address, JuhnzContract.abi, signer);

  console.log("isPublicMint__before");
  
  const res = await c.isPublicMint();

  console.log("isPublicMint__result", res);

  return res;
};

export const getTokenState = async (
  provider: JsonRpcProvider | undefined,
  account: string,
  amount: string,
  tokenId: number | string
) => {
  const signer = provider?.getSigner();
  if (!signer || !account) return;
  const c = new Contract(JuhnzContract.address, JuhnzContract.abi, signer);

  const res = await c.getTokenState(tokenId);

  console.log("getTokenState_result", res);

  return res;
};

export const mint = async (
  provider: JsonRpcProvider | undefined,
  account: string,
  amount: string,
  proof: any[]
) => {
  const signer = provider?.getSigner();
  if (!signer || !account) return;
  const c = new Contract(JuhnzContract.address, JuhnzContract.abi, signer);

  const isStarted = await isPublicMint(provider, account);

  if (!isStarted) {
    return Promise.reject("not start");
  }

  const value = parseEther((Number(amount) * 0.3).toFixed(1));
  console.log("etherValue~~~~~", value);

  const tx = await c.mint(Number(amount), proof, {
    value
  });

  await tx.wait?.();
};

export const redeem = async (
  provider: JsonRpcProvider | undefined,
  account: string,
  tokenIds: any[]
) => {
  const signer = provider?.getSigner();
  if (!signer || !account) return;
  const c = new Contract(JuhnzContract.address, JuhnzContract.abi, signer);

  const res = await c.redeem(tokenIds);

  console.log("redeem__result", res);

  return res;
};

export const getOwnedTokenCount = async (
  provider: JsonRpcProvider | undefined,
  account: string
) => {
  const signer = provider?.getSigner();
  if (!signer || !account) return;
  const c = new Contract(JuhnzContract.address, JuhnzContract.abi, signer);

  const res = await c.getOwnedTokenCount(account);

  console.log("redeem__result", res);

  return res?.toNumber?.();
};

export const getTotalSupply = async (
  provider: JsonRpcProvider | undefined,
  account: string
) => {
  const signer = provider?.getSigner();
  if (!signer || !account) return;
  const contract = new Contract(
    JuhnzContract.address,
    JuhnzContract.abi,
    signer
  );

  const res = await contract.totalSupply?.();

  console.log("totalSupply__result", res?.toNumber?.());

  return res?.toNumber?.() || 500;
};
