import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "@/state/hooks";
import { updateInviter, updateShowLoginDialog } from "@/state/user/reducer";
import { utils as ethersUtils } from "ethers";
import { homePageViewEvent } from "@/helper/utils/sensors";
import useTranslation from "@/hooks/useTranslation";
import { TwitterIcon } from "@/assets/svg-iconfont";
import { isPublicMint, getTotalSupply } from "@/helper/contract";
import { useWeb3React } from "@web3-react/core";
import { useDispatch } from "react-redux";
import WhiteListJSON from "@/whitelist.json";
import { getNftList, getNftStatus } from "../api";
import { useMedia } from "@/hooks/useMedia";

const HomePageLogoDesc = () => {
  const { isMobile, isBigPC } = useMedia();
  return (
    <div
      className={
        isMobile
          ? "page-home-profile mobile"
          : isBigPC
          ? "page-home-profile pc"
          : "page-home-profile"
      }
    >
      <div className="title">Juhnz</div>
      <div className="desc">
        Juhnz explores a new realm of possibilities as it unveils Juhnz Baekho
        Silver Needle, the first NFT backed by luxury White Tea sourced from the
        mountains of Fujian, China.
      </div>
    </div>
  );
};
const HomePageMintInfo = () => {
  const dispatch = useDispatch();
  const open = () => dispatch(updateShowLoginDialog({ showLoginDialog: true }));
  const { account, provider } = useWeb3React();
  const n = useNavigate();
  const { isMobile } = useMedia();
  const [mintInfo, setMintInfo] = useState<{
    endTime?: number;
    startTime?: number;
    status?: string;
  }>({});
  const [mintTitle, setMintTitle] = useState("");
  const isInWhiteList = useMemo(() => {
    if (!account) {
      return true;
    }
    // @ts-ignore
    return Boolean(WhiteListJSON[account]);
  }, [account]);

  useEffect(() => {
    if (!account) {
      getNftStatus().then((r) => {
        if (!r.isStart) {
          setMintTitle("Comming Soon");
        } else if (r.isEnd) {
          setMintTitle("Sold Out");
        } else {
          setMintTitle("Mint Live");
        }
      });
    } else {
      isPublicMint(provider, account).then(async (isStart) => {
        if (!isStart) {
          setMintTitle("Comming Soon");
        } else {
          getTotalSupply(provider, account).then((res) => {
            if (res === 500) {
              setMintTitle("Sold Out");
            } else {
              setMintTitle("Mint Live");
            }
          });
        }
      });
    }
  }, [account, provider]);

  useEffect(() => {
    setMintInfo({
      endTime: Date.now() + 1000000,
      startTime: Date.now() - 1000000,
    });

    if (!account) {
      return;
    }

    getNftList(account, provider)
      .then((r) => {
        console.log("nftList", r);
      })
      .catch((e) => {
        console.log("nft list error", e);
      });

    isPublicMint(provider, account).catch((e) => {});
  }, [account]);

  const doMint = () => {
    if (mintTitle === "Comming Soon") {
      return;
    }

    if (mintTitle === "Sold Out") {
      // goto Opensea
      window.open("https://opensea.io/collection/juhnz-baekho-silver-needle");
    } else if (mintTitle === "Mint Live") {
      if (!account) {
        open();
      } else {
        window.scrollTo(0, 0);
        n("/mint");
      }
    }
  };

  const isDisabled = ["Comming Soon"].includes(mintTitle);

  return (
    <div
      className={
        isMobile ? "page-home-mint page-home-mint-mobile" : "page-home-mint"
      }
    >
      <div className="home-left-mint">
        <div className="home-mint-info">
          <div className="title">{mintTitle}</div>
          <div className="desc">Juhnz Baekho Silver Needle</div>
          <div className="mint-item">
            <div className="t">Price </div>
            <div>0.3 ETH</div>
          </div>
          <div className="mint-item">
            <div className="t">Whitelist</div>
            <div>YES</div>
          </div>
          <div className="mint-item">
            <div className="t">Mint Limit</div>
            <div>5</div>
          </div>
          <div style={{ marginTop: "20px" }}>
            <button
              onClick={doMint}
              disabled={isDisabled}
              className={isDisabled ? "disabled" : ""}
            >
              {mintTitle === "Mint Live"
                ? "Mint Now"
                : mintTitle === "Sold Out"
                ? "View Opensea"
                : "Comming Soon"}
            </button>
          </div>
        </div>
      </div>
      <div className="juhnz-img"></div>
    </div>
  );
};

const HomePageDesc = () => {
  const { isMobile, isBigPC } = useMedia();
  return (
    <div
      className={
        isMobile
          ? "page-home-desc page-home-desc-mobile"
          : isBigPC
          ? "page-home-desc pc"
          : "page-home-desc"
      }
    >
      <div className="img-w">
        <img
          width={400}
          height={600}
          src="/images/juhnz3.png"
        />
      </div>
      <div className="intro-w">
        <div className="juhnz-title">What is Juhnz Baekho Silver Needle?</div>
        <div className="item-wrapper">
          <div className="item">
            <div className="item-title">Backed by Physical</div>
            <div className="item-desc">
              Each NFT represents one package of real rare White Tea sourced
              from Fujian China. This tea gets more valuable as it ages, as does
              its flavour.
            </div>
          </div>
          <div className="item">
            <div className="item-title">Authenticated</div>
            <div className="item-desc">
              Each NFT's data is etched onto the Ethereum blockchain forever.
              You can track ownership of your NFT all the way back to its
              original source. Rest assured knowing that your product is the
              real deal.
            </div>
          </div>
          <div className="item">
            <div className="item-title">Easily Tradeable</div>
            <div className="item-desc">
              No more challenges selling your Tea. Trade your NFT anytime on any
              permissionless marketplace like Opensea.
            </div>
          </div>
          <div className="item">
            <div className="item-title">Redeemable</div>
            <div className="item-desc">
              Each Juhnz NFT can be redeemed at any time for its physical
              product.
            </div>
          </div>
          <div className="item">
            <div className="item-title">Securely Stored</div>
            <div className="item-desc">
              We store and insure your Tea in the ideal temperature and
              humidity-controlled environments.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const HomePageTeamInfo = () => {
  const { isMobile, isBigPC } = useMedia();

  return (
    <div className={isMobile ? "team-mobile" : ""}>
      <div className="homepage-team-title">Our Team</div>
      <div
        className={
          "homepage-team-wrapper shabi" +
          (isMobile
            ? " homepage-team-wrapper-mobile person-mobile"
            : isBigPC
            ? " pc"
            : "")
        }
      >
        <div className="team-person">
          <img
            width={200}
            height={200}
            src="https://juhnz.web3app.vip/images/junie.png"
          />
          <div className="person-name">Junie</div>
          <div className="person-pos">CEO</div>
          <a
            href="https://twitter.com/NonFungibleJunz"
            target="_blank"
            rel="noreferrer"
          >
            <TwitterIcon />
          </a>
        </div>
        <div className="team-person">
          <img
            width={200}
            height={200}
            src="https://juhnz.web3app.vip/images/oscar.png"
          />
          <div className="person-name">Oscar</div>
          <div className="person-pos">COO</div>
          <a
            href="https://twitter.com/Oscarccg3"
            target="_blank"
            rel="noreferrer"
          >
            <TwitterIcon />
          </a>
        </div>
        <div className="team-person">
          <img
            width={200}
            height={200}
            src="https://juhnz.web3app.vip/images/nuo.png"
          />
          <div className="person-name">Nuo</div>
          <div className="person-pos">Creative Lead</div>
          <a
            href="https://twitter.com/Cloutbabes1"
            target="_blank"
            rel="noreferrer"
          >
            <TwitterIcon />
          </a>
        </div>
        <div className="team-person">
          <img
            width={200}
            height={200}
            src="https://juhnz.web3app.vip/images/tim.png"
          />
          <div className="person-name">Tim</div>
          <div className="person-pos">CMO</div>
          <a href="https://twitter.com/tkkl_" target="_blank" rel="noreferrer">
            <TwitterIcon />
          </a>
        </div>
      </div>
    </div>
  );
};

const PoweredBy = () => {
  const { isMobile, isBigPC } = useMedia();
  return (
    <>
      <div
        className="homepage-team-title"
        style={{ marginBottom: isMobile ? "20px" : "80px" }}
      >
        Backed By
      </div>
      <div
        className={
          "homepage-team-wrapper" +
          (isMobile ? " homepage-team-wrapper-blockby" : isBigPC ? " pc" : "")
        }
      >
        <img
          src="https://juhnz.web3app.vip/images/bixin.svg"
          width={223}
          height={65}
        />
        <img
          src="https://juhnz.web3app.vip/images/kk.png"
          width={216}
          height={70}
        />
      </div>
    </>
  );
};

const FAQ = () => {
  const { isMobile, isBigPC } = useMedia();
  const Item = ({ title = "", desc = "" }) => {
    return (
      <div className="faq-item">
        <div className="faq-item-title">{title}</div>
        <div className="faq-item-desc">{desc}</div>
      </div>
    );
  };

  return (
    <>
      <div
        className="homepage-team-title"
        style={{ marginBottom: isMobile ? "0px" : "80px" }}
      >
        FAQ
      </div>
      <div
        className={
          "homepage-team-wrapper" +
          (isMobile
            ? " homepage-team-wrapper-mobile faqmm"
            : isBigPC
            ? " pc"
            : "")
        }
        style={{ gap: "60px" }}
      >
        <div>
          <Item
            title="What is Juhnz?"
            desc="Juhnz is a luxury Web3 lifestyle brand that elevates Chinese culture through contemporary products that blend both tradition and innovation. The name Juhnz stems from the Confucius ideal of sophisticated character, and serves as a guiding point for all our products.

Juhnz offers a fresh take on traditional Chinese products like tea for the digital native consumer. Our philosophy is to deliver high quality products that are timeless, minimalistic and bold in aesthetic, that still pay homage to Chinese customs.

Each individual Juhnz product is outfitted with an NFC chip to verify authenticity and ownership. Juhnz plans to elevate the consumer-brand relationship through additional digital offerings and physical products that plot the future Web3 path of luxury goods."
          />
          <Item
            title="How does NFC work?"
            desc="Each individual Juhnz product is outfitted with an NFC chip that can be scanned by simply touching a mobile phone to the product. After successfully connecting, you will be prompted to open a web page on your mobile phone to verify the authenticity of your product."
          />
          <Item
            title="When is the mint?"
            desc="Juhnz Baekho Silver Needle NFT will be available on September 15."
          />
          <Item
            title="What price will the NFT be?"
            desc="The Juhnz Baekho Silver Needle NFT will be 0.3 ETH."
          />
          <Item
            title="Which chain is the NFT on?"
            desc="JuhnzBaekho Silver Needle NFT is on the Ethereum blockchain."
          />
          <Item
            title="How do I buy an NFT?"
            desc="The first collection of Juhnz Baekho Silver Needle is limited in supply and only available to those on our whitelist. If you wish to be whitelisted, apply on our website."
          />
          <Item
            title="What can I do with my NFT?"
            desc="You can redeem your Juhnz Baekho Silver Needle NFT for a physical box of Juhnz Baekho Silver Needle, sell the NFT on marketplaces such as Opensea, send the NFT to someone else as a gift, or keep the NFT in your collection."
          />
        </div>
        <div>
          <Item
            title="What happens when I redeem my NFT?"
            desc="Choosing to redeem your NFT will change its status from Redeemable to Redeemed. After redeeming your NFT, we will ask you for your shipping address to send the tea to."
          />
          <Item
            title="Where do you ship to?"
            desc="Juhnz ships to countries listed below. We also offer pick-up from select locations. United States, United Kingdom, Australia, Austria, Barbados, Belgium, Bermuda, British Virgin Islands, Cayman Islands, China, Christmas Island, Cyprus, Czechia, Falkland Islands, Germany, Gibraltar, Greece, Greenland, Hong Kong S. A. R, Iceland, Israel, Japan, Liechtenstein, Luxembourg, Macau S.A.R., Malta, New Caledonia, New Zealand, Norfolk Island, Norway, Panama, Portugal, Reunion, San Marino, Seychelles, Singapore, Slovenia, South Africa, South Korea, Switzerland, Holy See (Vatican City)"
          />
          <Item
            title="What happens if I sell my NFT?"
            desc="If you sell your NFT on a marketplace, everything will be documented and viewed on Etherscan. All of this is documented and can be viewed on Etherscan as well as the Juhnz website under your collection."
          />
          <Item
            title="Is Juhnz only going to sell tea?"
            desc="Juhnz launched its brand with a unique take on tea for the digital native consumer. We have plans to add more unique products in the future."
          />
          <Item
            title="How is the Tea stored?"
            desc="Our Juhnz Tea is secured directly at our warehouse in Fujian. This warehouse is bonded, temperature controlled and insured. Storing high-end white tea is difficult and expensive, so Juhnz takes care of this for you."
          />
          <Item
            title="How long can the Tea be stored for?"
            desc="We recommend to redeem and drink the Juhnz Baekho Silver Needle within 15 years. The taste, flavour, and value of Juhnz Baekho Silver Needle grows with age."
          />
        </div>
      </div>
    </>
  );
};

const HomePage = () => {
  const { address } = useParams();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    homePageViewEvent();
  }, []);

  useEffect(() => {
    if (address) {
      const useAddress = ethersUtils.getAddress(address);
      if (useAddress) {
        dispatch(updateInviter({ inviter: address }));
      }
    }
  }, [address]);

  const { isMobile } = useMedia();

  return (
    <div
      className={
        isMobile ? "page-home-container mobile" : "page-home-container"
      }
    >
      <HomePageLogoDesc />
      <HomePageMintInfo />
      <HomePageDesc />
      <HomePageTeamInfo />
      <PoweredBy />
      <FAQ />
    </div>
  );
};
export default HomePage;
