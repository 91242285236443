import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { useMemo } from "react";
import { I18nextProvider, initReactI18next } from "react-i18next";
import * as supportedLanguages from "../../i18n";
import { useAppSelector } from "@/state/hooks";
import type { LanguageKey, LanguageTranslationResources } from "./types";

export const I18nProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const language = useAppSelector((state) => state.user.language);

  const i18n = useMemo(() => {
    let resources = (Object.keys(supportedLanguages) as LanguageKey[]).reduce(
      (resources, lng) => {
        resources[lng] = {
          translation: supportedLanguages[lng],
        };
        return resources;
      },
      {} as LanguageTranslationResources
    );

    let i18n = i18next.createInstance({
      lng: language,
      fallbackLng: resources.en ? "en" : Object.keys(resources)?.[0],
      lowerCaseLng: false,
      interpolation: {
        escapeValue: false,
      },
      resources,
      detection: {
        caches: [],
      },
    });

    if (!language) {
      i18n = i18n.use(LanguageDetector);
    }

    i18n.use(initReactI18next).init();
    return i18n;
  }, [language]);

  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
};
